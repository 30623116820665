<template>
	<div>
		<button class="float-right btn btn-lg btn-success" @click="addDeviceList()">
			{{ this.showAddDevice ? 'Hide Add Device List' : 'Add Device' }}
		</button>
		<b-table :items="adminSite.data.devices" :fields="fields" striped hover>
			<template #cell(TypeId)="data">{{ deviceTypes[data.item.TypeId].Name }} </template>
			<template #cell(Name)="data"
				><span class="svg-icon svg-icon-sm" @click="removeDevice(data.item)" v-b-tooltip="'Remove device from Site'">
					<inline-svg src="/media/svg/icons/Code/Error-circle.svg"></inline-svg
				></span>
				{{ data.item.Name }}
				<span
					:class="['svg-icon', 'svg-icon-sm', data.item.properties && Object.keys(data.item.properties) != 0 ? 'svg-icon-success' : 'svg-icon-dark']"
					@click="dataToModal(data.item)"
					v-b-modal="'deviceModal-' + data.item.TypeId"
					v-b-tooltip="'Edit Device Properties'"
					v-if="deviceTypes[data.item.TypeId].Properties"
				>
					<inline-svg src="/media/svg/icons/Code/Settings4.svg"></inline-svg></span
			></template>
		</b-table>
		<div ref="deviceList">
			<SiteDeviceList v-show="showAddDevice" />
		</div>

		<b-modal id="deviceModal-10" @ok="ok()">
			<template #modal-title>Edit Solarwinds Device properties</template>
			<table class="table1 mt-10" v-if="devModalData.Name">
				<tr v-if="devModalData.Name.indexOf('Response Time') === -1">
					<td style="text-align: right">Show Daily Total Widget*:</td>
					<td><b-form-checkbox class="ml-5" v-model="devModalData.properties.dailytotal" /></td>
				</tr>
				<tr v-if="devModalData.Name.indexOf('Response Time') === -1">
					<td style="text-align: right">Show Interface Errors Widget**:</td>
					<td>
						<b-form-checkbox class="ml-5" v-model="devModalData.properties.interfaceerrors" />
					</td>
				</tr>
				<tr v-if="devModalData.Name.indexOf('Response Time') !== -1">
					<td style="text-align: right">Latency Offset (ms subtracted on graph):</td>
					<td>
						<b-form-input size="sm" class="ml-10" style="width: 100px" type="number" v-model="devModalData.properties.latencyoffset" />
					</td>
				</tr>
			</table>

			<span v-if="devModalData.Name && devModalData.Name.indexOf('Response Time') === -1">
				<br />
				<small>* Enables Solarwinds Daily Graph widget in Site Layout Add Widget dropdown</small>
				<br />
				<small>** Enables Solarwinds Interface Errors widget in Site Layout Add Widget dropdown</small>
			</span>
		</b-modal>

		<b-modal id="deviceModal-13" @ok="ok()">
			<template #modal-title>Edit Solarwinds Comtech Device properties</template>
			<div class="h4">{{ devModalData.Name }} Properties</div>
			<label for="selectDevice"></label>
			<b-form-select name="selectDevices" v-model="modalDevice" @change="loadSWComtechDevice()" :options="SWComtechOptions"></b-form-select>
			<form v-if="devModalData.properties && devModalData.properties.remote">
				<table class="table1">
					<tr>
						<td style="text-align: right">Latitude:</td>
						<td>
							<b-form-input size="sm" class="ml-5 w-50" type="text" v-model="devModalData.properties.remote.lat" valid-number />
						</td>
					</tr>
					<tr>
						<td style="text-align: right">Longitude:</td>
						<td>
							<b-form-input size="sm" class="ml-5 w-50" type="text" v-model="devModalData.properties.remote.lon" valid-number />
						</td>
					</tr>
					<tr>
						<td style="text-align: right">Antenna Size:</td>
						<td>
							<b-form-input size="sm" class="ml-5 w-50" type="text" v-model="devModalData.properties.remote.antsize" />
						</td>
					</tr>
					<tr>
						<td style="text-align: right">Satellite Longitude:</td>
						<td>
							<b-form-input size="sm" class="ml-5 w-50" type="text" v-model="devModalData.properties.remote.satlon" />
						</td>
					</tr>
					<tr>
						<td style="text-align: right">Band:</td>
						<td>
							<b-form-select class="ml-5 w-50" v-model="devModalData.properties.remote.band" :options="['C', 'Ku']" />
						</td>
					</tr>
				</table>

				<br />
				<h4>Teleport Properties:</h4>
				<table class="table1">
					<tr>
						<td style="text-align: right">Latitude:</td>
						<td>
							<b-form-input size="sm" class="ml-5 w-50" type="text" v-model="devModalData.properties.hub.lat" valid-number />
						</td>
					</tr>
					<tr>
						<td style="text-align: right">Longitude:</td>
						<td>
							<b-form-input size="sm" class="ml-5 w-50" type="text" v-model="devModalData.properties.hub.lon" valid-number />
						</td>
					</tr>
					<tr>
						<td style="text-align: right">Antenna Size:</td>
						<td>
							<b-form-input size="sm" class="ml-5 w-50" type="text" v-model="devModalData.properties.hub.antsize" />
						</td>
					</tr>
					<tr>
						<td style="text-align: right">Satellite Longitude:</td>
						<td>
							<b-form-input size="sm" class="ml-5 w-50" type="text" v-model="devModalData.properties.hub.satlon" />
						</td>
					</tr>
					<tr>
						<td style="text-align: right">Band:</td>
						<td>
							<b-form-select class="ml-5 w-50" v-model="devModalData.properties.hub.band" :options="['C', 'Ku']" />
						</td>
					</tr>
				</table>
			</form>
		</b-modal>

		<b-modal id="deviceModal-23" size="lg" @ok="ok()">
			<template #modal-title>Edit Starlink Device properties</template>
			<table class="table1 mt-10" v-if="devModalData.Name">
				<tr>
					<td style="text-align: right; padding-right:5px;  white-space: nowrap; max-width:50px;">Display Name:</td>
					<td>
						<b-form-input type="text" v-model="devModalData.properties.label" />
					</td>
				</tr>
			</table>
			<span>
				<br />
				<small>* This will override the default name for all associated graphs.</small>
			</span>
		</b-modal>

	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { REFRESH_ADMINEDIT_SITE } from '@/core/services/store/admin.module';

export default {
	name: 'SiteDevices',
	props: ['activeTab'],
	data() {
		return {
			fields: [{ key: 'TypeId', label: 'Type' }, { key: 'Name' }, { key: 'Source' }],
			devModalData: {},
			modalDevice: null,
			SWComtechOptions: [],
			showAddDevice: false,
			rd: {},
		};
	},
	components: {
		SiteDeviceList: () => import('@/view/content/widgets/admin/editsite/SiteDeviceList.vue'),
	},
	methods: {
		addDeviceList() {
			!this.showAddDevice && this.$refs.deviceList.scrollIntoView({ behavior: 'smooth' });
			this.showAddDevice = !this.showAddDevice;
		},
		removeDevice(d) {
			this.$bvModal.msgBoxConfirm('Remove device: ' + d.Name + ' from site?').then(value => {
				if (value) {
					this.$http.post('removesitedevice', { SiteId: this.adminSite.id, DeviceId: d.DeviceId }).then(() => {
						// add code to remove deleted device from layout then save layout

						this.$store.commit(REFRESH_ADMINEDIT_SITE);
						this.activeTab = 0;
					});
				}
			});
		},
		dataToModal(item) {
			this.devModalData = JSON.parse(JSON.stringify(item));
			if (!this.devModalData.properties) {
				this.devModalData.properties = {};
			}
			if (item.TypeId == 13) {
				this.$http.get('sionswcomtech').then(resp => {
					this.SWComtechOptions = [];
					if (resp.data && resp.data.data) {
						resp.data.data.forEach(swo => {
							this.SWComtechOptions.push({ value: swo.id, text: swo.site_name + ' - ' + swo.name });
						});
					}
				});
				if (!this.devModalData.properties.remote) {
					this.devModalData.properties.remote = {};
				}
				if (!this.devModalData.properties.hub) {
					this.devModalData.properties.hub = {};
				}
			}
		},
		loadSWComtechDevice() {
			this.$http.get(`sionswcomtech/${this.modalDevice}`).then(resp => {
				if (resp.data.data.hub.id != null) {
					this.devModalData.properties.hub = resp.data.data.hub;
				}
				if (resp.data.data.remote.id != null) {
					this.devModalData.properties.remote = resp.data.data.remote;
				}
			});
		},
		ok() {
			this.$http.post(`deviceproperties/${this.devModalData.DeviceId}`, this.devModalData.properties).then(() => {
				this.$store.commit(REFRESH_ADMINEDIT_SITE);
			});
		},
	},
	computed: {
		...mapGetters(['adminSite', 'deviceTypes']),
	},
};
</script>

<style>
.table1 {
	width: 100%;
	margin: 1rem;
	border: 0px;
}
</style>
